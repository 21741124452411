import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(public router: Router) { }

	canActivate(): boolean {
		if (localStorage.getItem('isLoggedIn') !== 'true') {
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
	canActivateChild(): boolean {
		if(localStorage.getItem('isGdprConsent') !== 'true'){
			this.router.navigate(['consent']);
			return false;
		}
		return true;
	}
}
