import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth-guard.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
	{ path:'', 
		loadChildren:'./layout/layout.module#LayoutModule', 
		canActivate:[AuthGuard], 
		canActivateChild: [AuthGuard] 
	},
	{ path:'login', loadChildren:'./login/login.module#LoginModule' },
	{ path:'forgotPassword', loadChildren:'./forgot-password/forgot-password.module#ForgotPasswordModule' },
	{ path:'consent', loadChildren:'./consent/consent.module#ConsentModule', canActivate:[AuthGuard] },
	{ path:'resetPassword/:userId/:requestId/:token', loadChildren:'./reset-password/reset-password.module#ResetPasswordModule' },
	{ path:'setPassword/:userId', loadChildren:'./set-password/set-password.module#SetPasswordModule' },
	{ path:'termsCondition', loadChildren:'./terms-condition/terms-condition.module#TermsConditionModule' },
	{ path:'privacyPolicy', loadChildren:'./privacy-policy/privacy-policy.module#PrivacyPolicyModule' },
	{ path:'accountManager', loadChildren:'./account-manager/account-manager.module#AccountManagerModule' },
	{ path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
