import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	previousUrl: string;
	constructor(private toastr: ToastrService, private router:Router, private renderer: Renderer2) { 
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				let currentUrlSlug = event.url.slice(1)
				currentUrlSlug = currentUrlSlug.split('/')[0];
				if (currentUrlSlug == 'login' || currentUrlSlug == 'resetPassword' || currentUrlSlug == 'forgotPassword' || currentUrlSlug == 'setPassword') {
					this.renderer.addClass(document.body, 'full-bg');
				}
				this.previousUrl = currentUrlSlug
				if (!(this.previousUrl == 'login' || this.previousUrl == 'resetPassword' || this.previousUrl == 'forgotPassword' || this.previousUrl == 'setPassword')) {
					this.renderer.removeClass(document.body, 'full-bg');
				}
			}
		});
	}

}
